import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownOption } from 'components/dropdown/component'
import { isNil } from 'lodash'
import { BillingPlanCode } from 'shared_server_client/types/billing_plan'
export const RolesOptions: DropdownOption[] = [
  {key: 'community_admin', description: 'Admin'},
  {key: 'member', description: 'Contacts Admin'},
  {key: 'reports', description: 'Report Admin'},
  {key: 'integrations_admin', description: 'Integration Admin'},
]

export const ENTERPRISE_UPGRADE_MAIL_TO = 'mailto:support@rasa.io?subject=Enterprise Upgrade'

export const UPDATE_CARD_URL = DashboardMenuOption.billing + '#billing'
export const INTEGRATIONS_URL = DashboardMenuOption.settings + '/integrations'
export const DOMAINS_URL = DashboardMenuOption.settings + '/domain'
export const INTEGRATIONS_CONFIG_URL = INTEGRATIONS_URL + '/config'
export const INVITATION_SENT = 'Invitation Sent!'
export const TRANSFER_SUCCESS = 'User transferred successfully!'
export const TRANSFER_FAILED = 'Oh no, something went wrong with that attempt!'
export const USER_UPDATED = 'User updated successfully!'
export const INVITATION_FAILED = 'Oh no, something went wrong with that attempt!'

export const DEFAULT_SALESFORCE_CUSTOM_FILTER = 'RasaNewsletter__c'
export const DOMAIN_AUTH_SUCCESS = 'The DNS records have been created. Please read the documentation on how to validate.'
export const DOMAIN_AUTH_FAILURE = 'Oh no, something went wrong with that attempt and we could not create your records.'
export const DOMAIN_DELETE_SUCCESS = 'The DNS records have been deleted successfully.'
export const DOMAIN_DELETE_FAILURE = 'Oh no, something went wrong with that attempt and we could not delete your records.'
export const DOMAIN_VERIFY_FAILURE = 'Your DNS records do not appear to be set correctly, please double check them then try again.'
export const DOMAIN_VERIFY_SUCCESS = 'Woo hoo!  Your DNS records are correct, you can now send from this domain!'
export const PREFERENCE_UPDATE_SUCCESS = 'Woo hoo! Your preference updated successfully.'
export const PREFERENCE_UPDATE_FAILURE = 'Oh no, something went wrong with that attempt and we could not update your preference.'

export const BOTH = 'both'
export const TO_RASA = 'to_rasa'
export const SELECT_AN_OPTION = 'Select an Option'
export const SELECT_AN_AUDIENCE = 'Select an Audience'
export const SELECT_A_SUBSCRIPTION_TYPE = 'Select a Subscription Type'
export const SELECT_A_LIST_TYPE = 'Select a Subscription Type'
export const ALL_CONTACTS = 'All Contacts'
export const ALL_ROLES = 'All Roles'
export const ALL_SUBSCRIPTIONS = 'All Subscriptions'
export const CONSENT_INTEGRATIONS_IMPORT = 'ConsentIntegrationsImport'
export const CUSTOM_FILTER = 'Custom'
export const CUSTOM_FILTER_VALUE = 1
export const SUBSCRIPTION_TYPE = 'subscription_type'
export const MEMBER_STATUS_ACCOUNT = 'account'
export const MEMBER_STATUS_INVITATION = 'invitation'
export const ACCOUNT_STATUS_ACTIVE = 'Active'
export const ACCOUNT_STATUS_ARCHIVED = 'Archived'
export const ACCOUNT_STATUS_EXPIRED = 'Expired'
export const ACCOUNT_STATUS_INACTIVE = 'Inactive'
export const ACCOUNT_STATUS_PENDING = 'Pending'
export const CREATE = 'Create'
export const UPDATE = 'Update'
export const SUCCESS = 'Success'
export const FAILURE = 'Failure'
export const LANGUAGE_CONFIGURATION_KEY = 'supported_languages'
export enum IdentityProviders {
  cognito = 'cognito',
  google = 'google',
  facebook = 'facebook',
}

export const FilterTypeToNameMap = {
  list: 'List',
  subscription_type: 'Subscription Type',
  audience: 'Audience',
  filter: 'Filter',
}
export enum CommunitySystemStatus {
  Disconnected = 'disconnected',
  Reconnected = 'reconnected',
}

export interface RasaEvent {
  label: string,
  value: string,
}
export const RasaEventOptions: RasaEvent[] = [
  { label: 'Bounce', value: 'hard_bounce' },
  { label: 'Click', value: 'click' },
  { label: 'Open', value: 'open' },
  { label: 'Spam', value: 'spamreport' },
]

export interface EmailPreference {
  name: string,
  heading: string,
  description: string
}

export const EmailPreferences: EmailPreference[] = [
  {
    name: 'content_review',
    heading: 'Content Ready for Review Email',
    description: 'Notifies you when automatically pulled content is ready to \
                  review before a send. Based on your content cut off time.',
  },
  {
    name: 'generate_report',
    heading: 'Weekly Report Email',
    description: 'Includes a weekly recap of statistics for your newsletter',
  },
  {
    name: 'user_subscribed',
    heading: 'Subscribed to the newsletter',
    description: 'Subscribed/Unsubscribed to the newsletter',
  },
  {
    name: 'schedule_pause',
    heading: 'Schedule Pause Notification Email',
    description: 'Notifies you when your schedule has been paused \
                  in the past 24 hours.',
  },
]

export interface Language {
  name: string,
  code: string,
}

export const Languages: Language[] = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Spanish',
    code: 'es',
  },
  {
    name: 'French',
    code: 'fr',
  },
  {
    name: 'German',
    code: 'de',
  },
  {
    name: 'Portuguese',
    code: 'pt',
  },
]

export const getAttribute = (props: any, key: string, defaultValue: any = null): any => {
  if ( isNil(props[key]) ) {
    return defaultValue || false
  } else {
    return props[key]
  }
}

export const AllowedRoles: DropdownOption[] = [
  {
    key: 'Regular User',
    value: 'member',
  },
  {
    key: 'Admin',
    value: 'community_admin',
  },
]

export const getRoleDisplayName = (role: string) => {
  const roles = AllowedRoles.filter((x) => x.value === role)
  return roles.length > 0 ? roles[0].key : AllowedRoles[0].key
}

export const getRoleByDisplayName = (role: string) => {
  const roles = AllowedRoles.filter((x) => x.key === role)
  return roles.length > 0 ? roles[0].value : AllowedRoles[0].value
}

export enum SalesForceFields {
  FILTER_NAME = 'customFilterName',
  SUBSCRIBED_FIELD_NAME = 'optoutFieldName',
}

export enum HubsportSourceType  {
  LISTTYPE = 'list',
  SUBSCRIPTIONTYPE = 'subscription_type',
}

export const RICH_INTEGRATION_BILLING_PLANS = [
  BillingPlanCode.ZOHO_PRO_2023.toString(),
  BillingPlanCode.ZOHO_PRO_2021.toString(),
  BillingPlanCode.RASA_ENTERPRISE.toString(),
]

export const SUBSCRIPTION_TYPE_NAME = 'subscription_type_name'

// System doesn't have the system_api_attribute in the community_system table
export const KEY_BASED_INTEGRATIONS = ['Pabbly','Zapier','Integrately','SyncSpider','Pipedream']
